const productsData = [
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727015709662_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_195050_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727015709870_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_201902_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727015709893_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_202204_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727015709681_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_201103_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Leather Polish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_93148487035600480",
    CategoryID: "cat_17253556539278027",
    Description:
      "The Grey Leather Polish finish features a sophisticated blend of grey tones with a subtly textured surface. This finish accentuates the natural rough texture of the stone, offering a matte appearance that mimics the feel of leather. Available in various shades of grey, it brings a modern, understated elegance to any space.",
    ProductName: "Grey Leather Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: "18mm - 22mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            ColorName: "Green",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726308673877_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_194800_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726308673903_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_202001_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726308673918_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_202048_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726308673886_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_201344_0000.png",
            ],
          },
        ],
        Finish: "Leather Polish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_94274357146421760",
    CategoryID: "cat_17253556539278027",
    Description:
      " The Green Leather Polish finish offers a captivating blend of refreshing green shades with a subtly textured surface. This finish highlights the natural stone’s rough texture, creating a matte look that emulates the feel of leather. Available in various green tones, it introduces a lively yet sophisticated touch to any space.",
    ProductName: "Green Leather Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: " 45mm - 50mm",
          },
          {
            Width: "8 inches",
            Length: " 8 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "8 inches",
            Length: " 8 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "45mm - 50mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309404927_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_161824_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309404931_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_162821_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309404953_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_182636_0000.png",
            ],
            ColorName: "Yellow",
          },
        ],
        Finish: " Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_10994496739450766",
    CategoryID: "cat_1725355725154987",
    Description:
      "The Yellow Cobble offers a vibrant and durable option for paving and landscaping. Made from high-quality Tandur stone, these cobblestones feature a warm yellow hue that adds a bright and inviting touch to any space. Their robust nature makes them ideal for a variety of applications, including driveways, pathways, and outdoor areas. \n\n \n\nAvailable in various sizes, Yellow Cobbles are designed to withstand heavy use and offer long-lasting performance. Their natural stone composition ensures durability and resistance to weathering, while their distinctive yellow color enhances the aesthetic appeal of both residential and commercial settings. \n\n \n\nWhether used to create charming walkways or elegant driveways, Yellow Tandur Cobbles combine functionality with visual appeal, making them a versatile choice for enhancing your outdoor spaces.",
    ProductName: "Yellow Cobble",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: "18mm - 22mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309630290_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_194714_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309630319_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_201838_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309630336_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_202248_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309630298_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_201502_0000.png",
            ],
            ColorName: "Yellow",
          },
        ],
        Finish: "Leather Polish",
        VariantID: "001A",
      },
    ],
    ProductID: "prod_17253589050667215",
    ProductId: "prod_7747758574684727",
    CategoryID: "cat_17253556539278027",
    Description:
      "The Yellow Leather Polish combines subtle texture with practical durability. Created through a process that enhances the stone's natural rough surface, this finish results in a matte, slightly textured appearance that mimics the feel of leather and gives a RICH GOLDEN FINISH \n\n",
    ProductName: "Yellow Leather Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "4 inches",
            Length: " 4 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: " 4 inches",
            Length: "4 inches",
            Thickness: " 35mm - 40mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "6 inches",
            Length: " 6 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: " 8 inches",
            Length: " 8 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: " 35mm - 40mm",
          },
          {
            Width: " 8 inches",
            Length: "8 inches",
            Thickness: "45mm - 50mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309741089_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_182956_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309741107_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_183259_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726309741085_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_182039_0000.png",
            ],
            ColorName: "Green",
          },
        ],
        Finish: " Natural",
        VariantID: "001A",
      },
    ],
    ProductID: "prod_17253616493207138",
    ProductId: "prod_84945353390533168",
    CategoryID: "cat_1725355725154987",
    Description:
      "The Green Cobble is an excellent choice for adding a touch of natural elegance to your outdoor spaces. These cobblestones come in a refreshing green shade that brings a vibrant and earthy feel to any area. They are perfect for paving driveways, pathways, and landscaping projects. \n\nAvailable in various sizes, Green Tandur Cobbles are known for their durability and resistance to wear, making them suitable for both high-traffic and decorative applications. The natural stone composition ensures longevity and resilience against the elements, while the unique green hue enhances the visual appeal of residential and commercial spaces alike. \n\nIdeal for creating stylish walkways or standout driveways, Green Tandur Cobbles offer both functionality and aesthetic charm, making them a versatile and attractive option for your outdoor design needs.",
    ProductName: "Green Cobble",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "17 inches",
            Length: "17 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "11 inches",
            Length: " 17 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: " 18mm - 22mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209498713_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_230821_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209511013_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240811_204604_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209505634_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_231756_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209527108_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240811_210200_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209520141_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240811_205728_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: " Slice Polish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_49922036033427328",
    CategoryID: "cat_1725355554757286",
    Description:
      "A top-quality polished stone, offering a smoother and more refined finish than most other high-polish stones. It's precisely machine-cut with perfect 90-degree angles, So it fits together easily during installation—no extra cutting needed. \n\nThis stone is durable and slip-resistant, making it a great choice for indoor spaces in your home. Plus, it's available in different sizes to suit your needs. With its long-lasting quality and easy installation, this Tandur stone centerpiece is a stylish and practical addition to any room.",
    ProductName: "Center Piece – Slice Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "22mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: "22mm - 30mm",
          },
          {
            Width: " 17 inches",
            Length: " 17 inches",
            Thickness: "22mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: " 23 inches",
            Thickness: "22mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: "17 inches",
            Thickness: "22mm - 30mm",
          },
          {
            Width: " 11 inches",
            Length: "11 inches",
            Thickness: "22mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209446774_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240830_194256_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209423678_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240811_204604_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727016122045_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_231834_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209440645_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240811_210200_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209432141_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240811_205728_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: " Slice Polish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_13537954190463130",
    CategoryID: "cat_1725355554757286",
    Description:
      "Side piece is designed for both residential and commercial use, including shops, railways, and other high-traffic areas. Slightly thicker than the centerpiece, it offers enhanced durability. One face is polished to a smooth finish, while the other side remains natural. \n\n \n\nMachine-cut with precise 90-degree angles, this natural stone is easy to install without the need for hand-cutting. Its slip-resistant surface and robust construction make it a reliable choice for various applications. Available in different sizes, it provides both practicality and longevity.",
    ProductName: " Side Piece – Slice Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: "18mm - 22mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209688883_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_195853_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209703677_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_201759_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209710573_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_202335_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726209696949_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_201714_0000.png",
            ],
            ColorName: "Brown",
          },
        ],
        Finish: "Leather Polish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_33414090180597112",
    CategoryID: "cat_17253556539278027",
    Description:
      "The Brown Leather Polish finish features a unique blend of rich brown hues and subtle texture, adding a sophisticated yet natural touch to any space. Available in a range of brown shades, this finish enhances the stone's visual appeal with warm, earthy tones. The Tandur Leather Polish is achieved through a process that highlights the stone's natural rough surface, creating a matte, slightly textured look that resembles leather.",
    ProductName: "Brown Leather Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: " 8 inches",
            Length: " 8 inches",
            Thickness: " 45mm - 50mm",
          },
        ],
        Colors: [
          {
            Edge: "Hand Cutting",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726311021509_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_213534_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726311021494_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_213046_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726311021510_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_213852_0000.png",
            ],
            ColorName: "Yellow",
          },
        ],
        Finish: "Natural",
        VariantID: "001B",
      },
    ],
    ProductID: "prod_17253901170424937",
    ProductId: "prod_16690668529759556",
    CategoryID: "cat_1725355725154987",
    Description:
      "Yellow Cobble Handcut Stone is a naturally durable stone with a rustic and textured finish, achieved through skilled hand cutting. This cobble stone is available in a warm yellow hue, adding a charming and traditional look to outdoor spaces. Ideal for pathways, driveways, patios, and landscaping projects, it offers both aesthetic appeal and functionality. The handcut edges enhance its natural beauty, making it a perfect choice for creating timeless and enduring surfaces.",
    ProductName: "Yellow Cobble-Handcut",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: " 8 inches",
            Length: " 8 inches",
            Thickness: " 35mm - 40mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: " 45mm - 50mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726313411966_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_183050_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726313411967_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_183154_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726313411949_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_182344_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural",
        VariantID: "001B",
      },
    ],
    ProductId: "prod_29858466694580456",
    CategoryID: "cat_1725355725154987",
    Description:
      "Grey Cobble Handcut Stone is a robust and visually appealing option, featuring a natural grey tone that complements a variety of outdoor settings. Carefully crafted through hand cutting, this cobble stone has a textured surface and irregular edges, adding a touch of authenticity and rustic charm. It is ideal for use in pathways, driveways, patios, and landscaping, offering both durability and aesthetic appeal. The grey hue provides a versatile and classic look, making it suitable for creating elegant and lasting outdoor surfaces.",
    ProductName: "Grey Cobble",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: " 8 inches",
            Length: " 8 inches",
            Thickness: " 45mm - 50mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726070755824_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_182927_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726070765340_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_183225_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726070746314_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_182211_0000.png",
            ],
            ColorName: "Brown",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17260709332583243",
    CategoryID: "cat_1725355725154987",
    Description:
      "The Brown Cobble combines rustic charm with exceptional durability, making it an excellent choice for various paving and landscaping applications. Crafted from premium Tandur stone, these cobblestones showcase a rich brown hue that adds a warm and inviting touch to outdoor areas. They are ideal for driveways, pathways, and decorative features. \n\nAvailable in multiple sizes, Brown Tandur Cobbles are built to withstand heavy traffic and resist wear over time. Their natural stone composition ensures lasting strength and weather resistance, while the earthy brown color enhances the visual appeal of both residential and commercial spaces. \n\nWhether used to create charming walkways or robust driveways, Brown Tandur Cobbles provide both aesthetic beauty and practical performance, making them a versatile addition to any outdoor design.",
    ProductName: "Brown Cobble",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "2 feet",
            Length: "2 feet",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "2 feet",
            Length: "2 feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "2 feet",
            Length: "2 feet",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "2 feet",
            Length: "2 feet",
            Thickness: "45mm - 50mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727593333360_1.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727593333349_2.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727593333335_3.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727593333322_4.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727593333320_5.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727593333301_6.png",
            ],
            ColorName: "Brown, Green, Black, Grey, Yellow",
          },
        ],
        Finish: "Natural, Leather, Polished",
        VariantID: "001B",
      },
    ],
    ProductId: "prod_17260747892644011",
    CategoryID: "cat_17253559734044558",
    Description:
      "The Diamond-Cut Stone brings a touch of radiant elegance to your garden. With its faceted surfaces, this stone reflects light beautifully, creating a sparkling effect that enhances outdoor spaces. Adds a bright and cheerful atmosphere, making it perfect for garden pathways, borders, and decorative features. Durable and eye-catching, Diamond-Cut Yellow Stone is an excellent choice for adding both style and functionality to your garden",
    ProductName: "Diamond-Cut Finish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "4 inches",
            Length: "4 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "6 inches",
            Length: "6 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "8 inches",
            Length: "8 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: " 8 inches",
            Length: " 8 inches",
            Thickness: " 45mm - 50mm",
          },
        ],
        Colors: [
          {
            Edge: "Hand Cutting",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726071850116_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_214028_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726071873569_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_214118_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726071838686_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_213927_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural",
        VariantID: "001B",
      },
    ],
    ProductId: "prod_17260720225429250",
    CategoryID: "cat_1725355725154987",
    Description:
      "Grey Cobble Handcut Stone is a robust and visually appealing option, featuring a natural grey tone that complements a variety of outdoor settings. Carefully crafted through hand cutting, this cobble stone has a textured surface and irregular edges, adding a touch of authenticity and rustic charm. It is ideal for use in pathways, driveways, patios, and landscaping, offering both durability and aesthetic appeal. The grey hue provides a versatile and classic look, making it suitable for creating elegant and lasting outdoor surfaces.",
    ProductName: "Grey Cobble-Handcut",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "2 feet",
            Length: "2 feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "1.5 feet",
            Length: "1.5 feet",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727865993393_1.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727865993064_2.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727865993043_3.png",
            ],
            ColorName: "Brown, Green, Grey , Yellow",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17260750436236649",
    CategoryID: "cat_17253558480386072",
    Description:
      "The Round Tandur Natural Finish Stone is specifically designed for garden applications, adding a natural and timeless aesthetic to any outdoor space Known for its durability and weather resistance, this stone enhances garden landscapes with its earthy texture and robust build. Whether used as a decorative element, pathway accent, or to create unique outdoor patterns, its round shape and natural finish make it a perfect choice for enhancing the beauty of your garden while ensuring lasting performance in all weather conditions.",
    ProductName: "Round Natural Finish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "2 feet",
            Length: "2 feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "1.5 feet",
            Length: "1.5 feet",
            Thickness: " 25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726313126972_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240801_132757_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726313126950_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240731_204223_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726313126969_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240731_204331_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726313126986_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_160908_0000.png",
            ],
            ColorName: "Brown, Green, Black , Grey , Yellow",
          },
        ],
        Finish: "Polish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17260752342098937",
    CategoryID: "cat_17253558480386069",
    Description:
      "Our Round Chapati Maker Stone is made from Tandur Natural Stone and has a smooth, polished surface. It’s perfect for rolling chapatis or other flatbreads, thanks to its durability and even surface. This stone is also great for outdoor use in gardens, adding a natural and stylish look to pathways or landscaping. Whether you’re using it in the kitchen or outdoors, it’s a practical and attractive choice.",
    ProductName: "Chapati Maker",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203514672_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_143306_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203523225_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_143445_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203532341_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_143556_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203548722_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_144156_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203540645_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_144128_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Polished",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262036407336978",
    CategoryID: "cat_17253559205241743",
    Description:
      "The Grey High-Polish Stone is an excellent choice for flooring in government buildings and commercial spaces. It features a smooth, reflective surface that is both non-slippery and highly durable, making it suitable for high-traffic indoor areas. Available in various sizes, this stone offers a reliable, low-maintenance solution for flooring needs.",
    ProductName: "Grey High Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203771128_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240826_205644_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203746224_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_160609_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203755738_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_160639_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203727771_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_160259_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203736922_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_160526_0000.png",
            ],
            ColorName: "Yellow",
          },
        ],
        Finish: " Polished",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262038307261538",
    CategoryID: "cat_17253559205241743",
    Description:
      "The Yellow High-Polish Stone provides a luxurious flooring option for both commercial buildings and homes. Its high-polish finish delivers a bright, reflective surface that enhances the elegance of high-end interiors. The vibrant yellow color adds warmth and sophistication to any space. \n\nDesigned for durability and ease of maintenance, this stone is suitable for high-traffic areas and offers non-slip properties for safety. Available in various sizes, the Yellow High-Polish Stone combines functionality with a stylish look, making it an excellent choice for premium residential and commercial environments.",
    ProductName: "Yellow High Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726203989544_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_153513_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204027435_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_154423_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204053130_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_154457_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204015631_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_154151_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204002370_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_154125_0000.png",
            ],
            ColorName: "Brown",
          },
        ],
        Finish: "Polished",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262041048343166",
    CategoryID: "cat_17253559205241743",
    Description:
      "Elevate the look of any space with the Brown High-Polish Stone, a refined choice for both high-end commercial environments and sophisticated homes. Its glossy, high-polish surface provides a smooth, reflective finish that brings a touch of elegance and warmth to interiors. The deep brown color adds a sense of richness and complements a range of design themes. \n\nBuilt to withstand heavy use while maintaining its attractive appearance, this stone is durable and features a non-slip surface for added safety. Offered in various sizes, the Brown High-Polish Stone blends durability with a luxurious look, making it ideal for creating stunning floors in premium settings.",
    ProductName: "Brown High Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204218066_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_092656_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204200328_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_092216_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204209501_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_092310_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204191434_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_091859_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204183041_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_091649_0000.png",
            ],
            ColorName: "Brown and Green Mix",
          },
        ],
        Finish: "Polished",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262042858296525",
    CategoryID: "cat_17253559205241743",
    Description:
      "The Brown Chita Stone offers a distinctive and refined flooring option for both premium commercial and residential spaces. Predominantly brown in color, this stone features subtle flecks of green and grey that create a unique, textured appearance. The high-polish finish enhances its natural beauty, adding a sophisticated and warm touch to any interior. \n\nDesigned for durability and ease of maintenance, the Brown Chita Stone is ideal for high-traffic areas, providing a non-slip surface for safety. Available in various sizes, it combines practical functionality with an eye-catching design, making it a versatile choice for stylish floors in upscale environments.",
    ProductName: "Brown Chita High Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204375013_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240826_203014_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204382879_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240826_204344_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204409796_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240826_205934_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204391774_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240826_204601_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204400041_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240826_205035_0000.png",
            ],
            ColorName: "Green",
          },
        ],
        Finish: "Polished",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262044576108364",
    CategoryID: "cat_17253559205241743",
    Description:
      "Enhance your space with the Green High-Polish Stone, a versatile option for both commercial and residential settings. The stone's polished finish provides a smooth, reflective surface that adds a touch of elegance to any room. The rich green color brings a natural element to the design, complementing a variety of styles. \n\n \n\nBuilt to last, this stone is durable and maintains its attractive appearance even with regular use. Its non-slip surface adds an extra layer of safety, making it suitable for various applications. Available in different sizes, the Green High-Polish Stone is a reliable choice for creating visually appealing interiors.",
    ProductName: "Green High Polish",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "45mm - 50mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "45mm - 50mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204645604_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_204128_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204673334_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205405_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204682157_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205444_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204654514_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205112_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204662983_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205250_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262047425869834",
    CategoryID: "cat_17253559734044558",
    Description:
      "- Ideal for extreme heavy-duty applications and high-traffic areas \n\n- Can withstand intense wear and tear \n\n- Suitable for large commercial parking lots, highways, and industrial areas \n\n- Offers maximum durability and strength, making it a long-lasting option \n\n- Thickness tolerance: 45-50mm (natural stone variability) \n\n \n\nPlease note that the thickness tolerances are due to the natural variability of the stone, and the actual thickness may vary slightly depending on the specific pieces \n\n",
    ProductName: "50mm",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "35mm - 40mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "35mm - 40mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204838580_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_204543_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204867836_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205405_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204886637_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205444_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204847184_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205112_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726204859238_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205250_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262049655123592",
    CategoryID: "cat_17253559734044558",
    Description:
      "- Recommended for heavy vehicular traffic and large parking areas \n\n- Can withstand significant wear and tear \n\n- Ideal for commercial parking lots, highways, and heavy-duty applications \n\n- Offers superior durability and strength \n\n- Thickness tolerance: 35-40mm (natural stone variability) \n\nPlease note that the thickness tolerances are due to the natural variability of the stone, and the actual thickness may vary slightly depending on the specific pieces.",
    ProductName: "40mm",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 Inches",
            Length: "17 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205148980_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_204637_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205176178_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205405_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205183097_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205444_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205158266_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205112_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205167543_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205250_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262052522859946",
    CategoryID: "cat_17253559734044558",
    Description:
      "- Suitable for regular vehicular traffic and parking lots \n\n- Can withstand moderate wear and tear \n\n- Ideal for driveways, parking spaces, and garage floors \n\n- Offers a good balance between durability and affordability \n\n- Thickness tolerance: 25-30mm (natural stone variability) \n \n\nPlease note that the thickness tolerances are due to the natural variability of the stone, and the actual thickness may vary slightly depending on the specific pieces. \n\n",
    ProductName: "30mm",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "15mm to 20mm",
          },
          {
            Width: "23 inches",
            Length: "17 inches",
            Thickness: "15mm to 20mm",
          },
          {
            Width: "11 inches",
            Length: "23 inches",
            Thickness: "15mm to 20mm",
          },
          {
            Width: "11 inches",
            Length: "11 inches",
            Thickness: "15mm to 20mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205337986_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_204425_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205366229_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205405_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205376885_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205444_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205347591_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205112_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205357116_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_205250_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262054482492244",
    CategoryID: "cat_17253559734044558",
    Description:
      "- Ideal for pedestrian pathways and light vehicular traffic \n\n- Suitable for parking lots with occasional car movement \n\n- Easy to install and maintain \n\n- Cost-effective option \n\n- Thickness tolerance: 15-20mm (natural stone variability) \n\n. \n\n \n\nPlease note that the thickness tolerances are due to the natural variability of the stone, and the actual thickness may vary slightly depending on the specific pieces.",
    ProductName: " 20mm",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: " 17 inches",
            Length: " 17 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "17 inches",
            Length: " 17 inches",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            ColorName: "Brown, Green, Black , Grey , Yellow",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205577984_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_141122_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205586412_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_141440_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205595322_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_142028_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205604240_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_142439_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205612984_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_142747_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205621108_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_144615_0000.png",
            ],
          },
        ],
        Finish: "Natural ,Leather",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262056688986401",
    CategoryID: "cat_17253559734044558",
    Description:
      "The Square Pattern Stone features a distinctive square design that adds a modern and organized look to outdoor areas. Ideal for applications such as parking lots and garden pathways, this stone combines both functionality and aesthetic appeal. \n\nAvailable in a range of colors and sizes, the Square Pattern Stone is designed for durability and performance in various outdoor settings. Its robust construction ensures it can withstand the elements, making it a reliable choice for enhancing any exterior space",
    ProductName: "Square Pattern",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: " 17 inches",
            Length: " 17 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "17 inches",
            Length: " 17 inches",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205747032_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_143042_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205755783_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_143346_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205767076_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_143725_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205777823_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_144157_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205787998_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_145001_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205800532_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_145725_0000.png",
            ],
            ColorName: "Brown, Green, Black , Grey , Yellow",
          },
        ],
        Finish: "Natural, Leather",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262058497284560",
    CategoryID: "cat_17253559734044558",
    Description:
      "The Diamond Pattern Stone features a striking diamond design that adds a touch of sophistication to outdoor areas. Perfect for applications such as parking lots and garden pathways, this stone combines functional durability with an eye-catching geometric pattern. \n\nAvailable in a variety of colors and sizes, the Diamond Pattern Stone is built to withstand the elements while providing a visually appealing surface. Its robust construction ensures reliable performance across different outdoor settings, making it a versatile choice for enhancing any exterior space.",
    ProductName: "Diamond Pattern",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "23 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: " 17 inches",
            Length: " 17 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "17 inches",
            Length: " 17 inches",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726206503882_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_150323_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726206513159_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_150653_0000.png",
            ],
            ColorName: "Brown, Green, Black , Grey , Yellow",
          },
        ],
        Finish: "Natural, Leather",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262065631534312",
    CategoryID: "cat_17253559734044558",
    Description:
      "The Line Pattern Stone features a sleek, linear design that adds a contemporary touch to outdoor areas. Ideal for use in parking lots and garden pathways, this stone offers a blend of modern aesthetics and practical functionality. \nAvailable in various colors and sizes, the Line Pattern Stone is designed for durability and resilience in outdoor settings. Its robust construction ensures it can handle exposure to the elements, making it a reliable and stylish choice for enhancing any exterior space.",
    ProductName: "Line Pattern",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: " 2 feet",
            Length: " 6 feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "2 Feet",
            Length: "5 Feet",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "2 Feet",
            Length: " 4 Feet",
            Thickness: " 25mm - 30mm",
          },
          {
            Width: "2 Feet",
            Length: "3.5 Feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: " 2 Feet",
            Length: " 3 Feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "2 Feet",
            Length: "2.5 Feet",
            Thickness: "25mm - 30mm",
          },
          // -------
          {
            Width: "23 inches (Machine Cut)",
            Length: "71 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches (Machine Cut)",
            Length: "59 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches (Machine Cut)",
            Length: "47 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches (Machine Cut)",
            Length: "41 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches (Machine Cut)",
            Length: "35 inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches (Machine Cut)",
            Length: "29 inches",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Quarry Cut & Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726206808808_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_205041_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726206815737_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_221009_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726206794453_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_204359_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726206802209_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_204503_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262070373148635",
    CategoryID: "cat_17255241012007679",
    Description:
      "The Tandur Natural Stone Slabs are perfect for enhancing outdoor areas with their robust and rustic appeal. Featuring a natural surface, these large slabs highlight the stone's inherent texture and character, making them ideal for applications such as driveways, patios, and walkways. \n\nAvailable in substantial sizes, these slabs are designed to withstand the elements and provide a long-lasting solution for various exterior settings. The natural surface ensures a non-slip texture, combining both functionality and aesthetic charm for any outdoor project.",
    ProductName: "Grey Natural Slab",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "47 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "41 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "35 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 Inches",
            Length: "29 Inches",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727020817162_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_225403_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727020817304_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_225517_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727020817148_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_201134_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727020817316_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_230053_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: " Leather",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262074573889675",
    CategoryID: "cat_17255241012007679",
    Description:
      " \n\nThe Leather Polish Stone Slab adds a sophisticated touch to indoor environments with its distinctive textured surface. This stone features a leather-like finish that combines style with practicality, making it ideal for flooring in homes and commercial interiors. \n\nAvailable in various sizes, these slabs offer a non-slip surface that enhances both the visual appeal and durability of any indoor space. The unique Leather Polish highlights the stone's natural character while providing a robust and easy-to-maintain option for interior design.",
    ProductName: "Grey Leather Slab",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "23 inches",
            Length: "59 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 inches",
            Length: "47 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: " 23 Inches",
            Length: "41 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 Inches",
            Length: "35 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "23 Inches",
            Length: "29 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "2 feet (Quarry cut)",
            Length: "5 feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "2 feet (Quarry cut)",
            Length: "4 feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "2 feet (Quarry cut)",
            Length: "3.5 feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "2 feet (Quarry cut)",
            Length: "3 feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "2 feet (Quarry cut)",
            Length: "2.5 feet",
            Thickness: "25mm - 30mm",
          },
          {
            Width: "2 feet (Quarry cut)",
            Length: "2 feet",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726207719203_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_200959_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726207736842_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_220921_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726207726301_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_202743_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726207732512_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240806_204744_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Polished",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262078481685694",
    CategoryID: "cat_17255241012007679",
    Description:
      " \n\nThe Polished Stone Slab features a smooth surface that adds a modern touch to indoor spaces. Suitable for flooring and various interior applications, this slab combines durability with a refined appearance. Available in different sizes, it provides a sleek and easy-to-maintain option for enhancing the look of any interior environment",
    ProductName: " Grey Polished Slab",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: " 11 Inches",
            Length: "35 Inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "11 Inches",
            Length: "41 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "11 Inches",
            Length: "47 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "11 Inches",
            Length: "53 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "11 Inches",
            Length: "59 Inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Length: "3 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: " 18mm - 22mm",
          },
          {
            Length: "3.5 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: " 18mm - 22mm",
          },
          {
            Length: "4 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: " 18mm - 22mm",
          },
          {
            Length: "4.5 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: " 18mm - 22mm",
          },
          {
            Length: "5 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: " 18mm - 22mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cutting & Quarry Cutting",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727679885778_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240730_230450_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726208072147_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240730_225800_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726208079106_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240730_225901_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726208055825_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240801_125355_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Polished",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_1726208187116971",
    CategoryID: "cat_17253560197007068",
    Description:
      "The Center Piece Steps feature a size-polished finish, providing a sleek and durable option for staircases. Available in various sizes, these steps are designed to enhance both the appearance and functionality of stairs, offering a sophisticated solution for any indoor or outdoor staircase.",
    ProductName: "Center Piece Steps",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: " 11 Inches",
            Length: "  35 Inches",
            Thickness: "20mm - 30mm",
          },
          {
            Width: "11 Inches",
            Length: "41 Inches",
            Thickness: "20mm - 30mm",
          },
          {
            Width: "11 Inches",
            Length: "47 Inches",
            Thickness: " 20mm - 30mm",
          },
          {
            Width: "11 Inches",
            Length: "53 Inches",
            Thickness: "20mm - 30mm",
          },
          {
            Width: "11 inches",
            Length: "59 Inches",
            Thickness: "20mm - 30mm",
          },
          {
            Length: "3 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: "20mm - 30mm",
          },
          {
            Length: "3.5 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: "20mm - 30mm",
          },
          {
            Length: "4 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: "20mm - 30mm",
          },
          {
            Length: "4.5 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: "20mm - 30mm",
          },
          {
            Length: "5 Feet",
            Width: "1 Feet -> Quarry Cutting",
            Thickness: "20mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cutting & Quarry Cutting",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727545749232_sidestep.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726208277096_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240730_225211_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726208285703_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240730_230959_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726208266939_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240801_125355_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Polished",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262083916879220",
    CategoryID: "cat_17253560197007068",
    Description:
      "The Side-Polished Steps feature a polished surface on one side and a natural texture on the other. Slightly thicker than center piece steps, these steps offer both durability and a refined look. Available in various sizes, they are designed to enhance staircases with a stylish appearance and practical functionality, making them ideal for both residential and commercial applications. \n\n",
    ProductName: " Side Piece Steps",
  },
  {
    ProductId: "prod_1726208155116971",
    ProductName: "Natural Steps",

    Type: "Natural",
    Material: "Tandur Stone",
    Description:
      "The Natural Steps offer a rugged, textured finish that highlights the stone's inherent beauty. Designed with built-in grip lines for enhanced safety, each step features a molding near the corner for a refined edge. Available in various sizes, these steps combine durability with a distinctive, natural look, making them ideal for both residential and commercial staircases.",
    Category: "Steps",
    CategoryID: "cat_17253560197007068",
    Variants: [
      {
        VariantID: "001A",
        Finish: "Natural",
        Colors: [
          {
            ColorName: "Grey",
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726334519383_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_154451_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726334519356_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_153854_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726334519381_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_154004_0000.png",
            ],
          },
        ],
        Sizes: [
          {
            Length: "35 Inches",
            Width: "11 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Length: "41 Inches",
            Width: "11 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Length: "47 Inches",
            Width: "11 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Length: "53 Inches",
            Width: "11 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Length: "59 Inches",
            Width: "11 Inches",
            Thickness: "18mm - 22mm",
          },
        ],
      },
    ],
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: " 11 Inches",
            Length: "35 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "11 Inches",
            Length: "41 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "11 inches",
            Length: "47 Inches",
            Thickness: "18mm - 22mm",
          },
          {
            Width: "11 inches",
            Length: "53 Inches",
            Thickness: " 18mm - 22mm",
          },
          {
            Width: "11 inches",
            Length: "59 Inches",
            Thickness: " 18mm - 22mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726208459003_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_153736_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Polished",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_17262085309051131",
    CategoryID: "cat_17253560197007068",
    Description:
      "The Center Finished Steps are designed with a polished finish and feature built-in grip lines for added safety. Each step includes a molding near the corner, providing a refined edge and enhancing the overall appearance. Available in various sizes, these steps combine aesthetic appeal with practical functionality, making them an excellent choice for both residential and commercial staircases.",
    ProductName: "Center Finished Step",
  },

  // wall cladding
  {
    ProductId: "prod_17262083916800220",
    ProductID: "prod_17262083916800220",
    ProductName: "Yellow Wall Cladding",
    Type: "Natural",
    Description:
      "Yellow Natural Tandur wall cladding is a bright and cheerful choice, perfect for creating a happy and uplifting atmosphere. The yellow tone is vibrant and energetic, and can complement bold and vibrant color schemes. With its natural texture and subtle variations in shade, yellow Tandur wall cladding adds a touch of sunshine to any space.",
    Category: "Wall Cladding",
    CategoryID: "cat_17253561552887938",
    IsRecommended: false,
    Variants: [
      {
        VariantID: "001A",
        Finish: "Natural",
        Colors: [
          {
            ColorName: "Yellow",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331323397_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_192357_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331323444_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193536_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331323406_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_192849_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331323460_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193731_0000.png",
            ],
          },
        ],
        Sizes: [
          {
            Length: "2 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "4 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "6 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "2 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
          {
            Length: "4 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
          {
            Length: "6 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
        ],
      },
    ],
  },
  {
    ProductId: "prod_17262993916879220",
    ProductID: "prod_17262993916879220",
    ProductName: "Green Wall Cladding",
    Type: "Natural",
    Description:
      "Green Natural Tandur wall cladding brings a touch of nature indoors, with a fresh and calming color that evokes feelings of serenity and balance. The green tone is perfect for creating a relaxing atmosphere, and can complement natural materials like wood and stone. With its unique texture and subtle variations in shade, green Tandur wall cladding adds a touch of natural beauty to any space.",
    Category: "Wall Cladding",
    CategoryID: "cat_17253561552887938",
    IsRecommended: false,
    Variants: [
      {
        VariantID: "001A",
        Finish: "Natural",
        Colors: [
          {
            ColorName: "Green",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331367327_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_192215_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331367344_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193554_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331367340_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193015_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331367360_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193647_0000.png",
            ],
          },
        ],
        Sizes: [
          {
            Length: "2 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "4 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "6 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "2 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
          {
            Length: "4 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
          {
            Length: "6 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
        ],
      },
    ],
  },
  {
    ProductId: "prod_17262083916079220",
    ProductID: "prod_17262083916079220",
    ProductName: "Grey Wall Cladding",
    Type: "Natural",
    Description:
      "Grey Natural Tandur wall cladding is a versatile and sophisticated choice, perfect for creating a neutral backdrop for any interior design style. The grey tone is calming and serene, and can complement a wide range of colors and materials. With its natural texture and subtle variations in shade, grey Tandur wall cladding adds depth and interest to any space.",
    Category: "Wall Cladding",
    CategoryID: "cat_17253561552887938",
    IsRecommended: false,
    Variants: [
      {
        VariantID: "001A",
        Finish: "Natural",
        Colors: [
          {
            ColorName: "Grey",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331482819_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_192326_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331482841_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193459_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331482837_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193337_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331482854_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193706_0000.png",
            ],
          },
        ],
        Sizes: [
          {
            Length: "2 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "4 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "6 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "2 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
          {
            Length: "4 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
          {
            Length: "6 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
        ],
      },
    ],
  },
  {
    ProductId: "prod_172620839168792111",
    ProductName: "Brown Wall Cladding",
    Type: "Natural",
    Description:
      "Brown Natural Tandur wall cladding is a warm and welcoming choice, perfect for creating a cozy and inviting atmosphere. The brown tone is earthy and natural, and can complement a wide range of materials and design styles. With its natural texture and subtle variations in shade, brown Tandur wall cladding adds depth and warmth to any space.",
    Category: "Wall Cladding",
    CategoryID: "cat_17253561552887938",
    IsRecommended: false,
    Variants: [
      {
        VariantID: "001A",
        Finish: "Natural",

        Colors: [
          {
            ColorName: "Brown",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331433645_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_192253_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331433680_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193515_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331433659_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193212_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726331433681_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_193802_0000.png",
            ],
          },
        ],
        Sizes: [
          {
            Length: "2 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "4 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "6 inches",
            Width: "12 inches",
            Thickness: "10mm to 15mm",
          },
          {
            Length: "2 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
          {
            Length: "4 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
          {
            Length: "6 inches",
            Width: "24 inches",
            Thickness: "20mm to 30mm",
          },
        ],
      },
    ],
  },
  // satin finish
  {
    ProductId: "prod_172620839168792881",
    ProductName: "Grey Satin Finish",
    Type: "Natural",
    Material: "Tandur Stone",
    Description:
      "The Grey Satin Finish Stone is a premium choice, offering a surface that sits perfectly between polished and mirror finishes. Its subtle sheen reflects light without the intense gloss of a mirror, creating an elegant and refined appearance. The stone is machine-cut for precise edges and is available in various sizes, ensuring versatility for a range of interior applications. Combining durability with a smooth, understated look, the Grey Satin Finish Stone is an ideal option for those seeking high-quality materials with a sophisticated finish.",
    Category: "Satin Finish",
    CategoryID: "cat_17253562110392769",
    IsRecommended: false,
    Variants: [
      {
        VariantID: "001A",
        Finish: "Satin",
        Colors: [
          {
            ColorName: "Grey",
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333164548_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240812_153638_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333164526_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240811_000531_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333164546_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240811_001137_0000.png",
            ],
          },
        ],
        Sizes: [
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "18mm - 25mm",
          },
          {
            Length: "17 inches",
            Width: "23 inches",
            Thickness: "18mm - 25mm",
          },
        ],
      },
    ],
  },
  {
    ProductId: "prod_18862083916879220",
    ProductName: "Yellow Satin Finish",
    Type: "Natural",
    Material: "Tandur Stone",
    Description:
      "The Yellow Satin Finish Stone brings a warm, elegant touch to any interior space. With a surface that lies between polished and mirror finishes, it offers a subtle sheen that softly reflects light, avoiding the high gloss of a mirror finish. This premium stone is machine-cut for precise edges and comes in various sizes, making it versatile for different design needs. The yellow tone adds a cheerful yet refined appearance, combining durability with a smooth, sophisticated finish that enhances the ambiance of any room.",
    Category: "Satin Finish",
    CategoryID: "cat_17253562110392769",
    IsRecommended: false,
    Variants: [
      {
        VariantID: "001A",
        Finish: "Satin",
        Colors: [
          {
            ColorName: "Yellow",
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333213511_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240812_154113_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333213492_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_084750_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333213466_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_160639_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333213495_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_085402_0000.png",
            ],
          },
        ],
        Sizes: [
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "18mm - 25mm",
          },
          {
            Length: "17 inches",
            Width: "23 inches",
            Thickness: "18mm - 25mm",
          },
        ],
      },
    ],
  },
  {
    ProductId: "prod_18862083916079220",
    ProductName: "Brown Satin Finish",
    Type: "Natural",
    Material: "Tandur Stone",
    Description:
      "The Brown Satin Finish Stone offers a rich, earthy tone that enhances any interior space with its understated elegance. This finish, which falls between polished and mirror, provides a subtle sheen that reflects light softly, avoiding the intense shine of a full gloss. Machine-cut for precision and available in various sizes, this premium stone is both versatile and durable. The brown satin finish adds a warm, sophisticated touch, making it an ideal choice for those seeking a balanced, refined look in their interior design.",
    Category: "Satin Finish",
    CategoryID: "cat_17253562110392769",

    Variants: [
      {
        VariantID: "001A",
        Finish: "Satin",
        Colors: [
          {
            ColorName: "Brown",
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333264241_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240812_153525_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333264225_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_145553_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333264227_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_154457_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726333264202_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240807_145510_0000.png",
            ],
          },
        ],
        Sizes: [
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "18mm - 25mm",
          },
          {
            Length: "17 inches",
            Width: "23 inches",
            Thickness: "18mm - 25mm",
          },
        ],
      },
    ],
  },
  {
    Type: "Natural",
    Material: "Roof Slabs",
    Variants: [
      {
        Sizes: [
          {
            Width: "2 Feet",
            Length: "2 Feet",
            Thickness: "1 inch",
          },
          {
            Width: "2 Feet",
            Length: "2 Feet",
            Thickness: "1.5 inch",
          },
          {
            Width: "2 Feet",
            Length: "2 Feet",
            Thickness: "2 inch",
          },
          {
            Width: "2 Feet",
            Length: "2 Feet",
            Thickness: "3 inch",
          },
        ],
        Colors: [
          {
            Edge: "Quarry Cutting",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726335584168_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_182249_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726335584206_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_182700_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726335584187_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_182400_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726335584221_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_212851_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726335584190_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_182514_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural Finish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_18855241012007679",
    CategoryID: "cat_17255241012007669",
    Description:
      "Roof Slabs are durable and versatile natural stone slabs, ideal for house slabs. Available in various thicknesses, these slabs offer flexibility to meet different structural needs. Their substantial size ensures easy installation, making them a reliable choice for creating strong and long-lasting surfaces. Perfect for residential applications, these slabs provide a solid foundation with the added benefit of customizable thickness to suit specific project requirements.",
    Category: "Outdoor",

    ProductName: "Roof Slabs",
  },
  {
    Type: "Natural",
    Material: "Wall Stone",
    Variants: [
      {
        Sizes: [
          {
            Width: "1 Feet",
            Length: "2 Feet",
            Thickness: "2 inch",
          },
          {
            Width: "1 Feet",
            Length: "2 Feet",
            Thickness: "3 inch",
          },
          {
            Width: "1 Feet",
            Length: "2 Feet",
            Thickness: "4 inch",
          },
          {
            Width: "1 Feet",
            Length: "2 Feet",
            Thickness: "5 inch",
          },
          {
            Width: "1 Feet",
            Length: "2 Feet",
            Thickness: "6 inch",
          },
        ],
        Colors: [
          {
            Edge: "Quarry Cutting",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726336015153_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_173128_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726336015170_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_173528_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726336015183_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_173758_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726336015127_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_172933_0000.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726336015150_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240827_173019_0000.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural Finish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_19253559734044558",
    CategoryID: "cat_11023559734044558",
    Description:
      "Wall Stone is a highly durable and strong natural stone, perfect for constructing boundary walls, house walls, and basement walls. Its substantial 2×1 feet size not only provides exceptional strength but also makes it easy to construct with, allowing for faster work completion. Ideal for residential and commercial projects, this wall stone offers a reliable, aesthetically pleasing, and efficient solution for creating sturdy and enduring structures.",
    Category: "Outdoor",
    ProductName: "Wall Stone",
  },
  // Natural Finish
  {
    Type: "Natural",
    Material: "Stone",
    Variants: [
      {
        Sizes: [
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "17 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "23 inches",
            Width: "11 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "11 inches",
            Width: "11 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727578477185_natural%20finish.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727866257374_2.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727579568977_secnd.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727579600833_thrd.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727579624149_frth.png",
            ],
            ColorName: "Brown",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_19253009734044558",
    CategoryID: "cat_17200241012007679",
    Description:
      "The Brown Natural Stone combines rustic charm with practical functionality, making it an excellent choice for a variety of outdoor applications. With its rich brown hues and natural, textured surface, this stone adds timeless elegance to any exterior setting. Ideal for use in parking areas, garden paths, and walkways, it provides a durable and attractive solution for outdoor spaces. Designed to withstand the elements, Brown Natural Stone is low-maintenance and resistant to wear. Its versatile nature allows it to enhance various outdoor features, from garden pathways to expansive parking areas, ensuring both style and durability in any outdoor environment.",
    Category: "Outdoor",
    ProductName: "Brown Natural Stone",
  },
  {
    Type: "Natural",
    Material: "Stone",
    Variants: [
      {
        Sizes: [
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "17 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "23 inches",
            Width: "11 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "11 inches",
            Width: "11 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727580152538_1.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727950865950_2.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727580152534_2.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727580152518_3.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727866560480_5.png",
            ],
            ColorName: "Green",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_19250059734044558",
    CategoryID: "cat_17200241012007679",
    Description:
      "The Green Natural Stone features a rich green hue and a natural, textured surface that enhances any outdoor setting. Ideal for applications such as garden paths, walkways, and landscaping features, this stone blends seamlessly with its surroundings, offering a fresh and vibrant look. Designed for high durability, making it a reliable choice for high-traffic areas. Whether used to create stylish garden pathways or to enhance parking areas, this stone combines aesthetic appeal with exceptional performance, making it a versatile addition to any exterior space.",
    Category: "Outdoor",
    ProductName: "Green Natural Stone",
  },
  {
    Type: "Natural",
    Material: "Stone",
    Variants: [
      {
        Sizes: [
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "17 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "23 inches",
            Width: "11 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "11 inches",
            Width: "11 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727583636409_1.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727583636381_2.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727583636334_3.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727583636326_4.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727866735384_5.png",
            ],
            ColorName: "Yellow",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_19250059734011558",
    CategoryID: "cat_17200241012007679",
    Description:
      "The Yellow Natural Stone showcases a vibrant yellow hue and a natural, textured surface that brings a cheerful and warm touch to any outdoor setting. Perfect for applications such as garden paths, walkways, and landscaping features, this stone integrates beautifully with its surroundings while adding a lively splash of color. Engineered for high durability, Yellow Natural Stone withstands various weather conditions and heavy foot traffic, making it a practical choice for outdoor areas. Whether used for creating eye-catching garden pathways or enhancing parking spaces, this stone combines visual appeal with robust performance, offering a versatile and enduring solution for exterior design.",
    Category: "Outdoor",
    ProductName: "Yellow Natural Stone",
  },
  {
    Type: "Natural",
    Material: "Stone",
    Variants: [
      {
        Sizes: [
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "17 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "23 inches",
            Width: "11 inches",
            Thickness: "25mm to 30mm",
          },
          {
            Length: "11 inches",
            Width: "11 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727584163590_1.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727584163570_2.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727584163552_3.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727584163547_4.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727584163530_5.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Natural",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_19250059734044511",
    CategoryID: "cat_17200241012007679",
    Description:
      "The Grey Natural Stone offers a subtle yet sophisticated touch to any outdoor setting with its cool, neutral tone and naturally textured surface. Ideal for applications such as garden paths, walkways, and landscaping features, this stone seamlessly blends into its environment while adding a refined elegance to the overall design. Crafted for high durability, Grey Natural Stone is designed to withstand various weather conditions and heavy foot traffic, making it a reliable choice for outdoor spaces. Whether used for creating understated garden pathways or enhancing driveways, this stone combines aesthetic appeal with lasting performance, providing a versatile solution for exterior design projects.",
    Category: "Outdoor",
    ProductName: "Grey Natural Stone",
  },
  // mirror finish
  {
    Type: "Natural",
    Material: "Stone",
    Variants: [
      {
        Sizes: [
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "18mm to 22mm",
          },
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727588580265_1.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727588580268_2.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727588580239_3.png",
            ],
            ColorName: "Green",
          },
        ],
        Finish: "Mirror Polish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_20150059734044558",
    CategoryID: "cat_17253562990392769",
    Description:
      "Green Mirror Polish Stone is a distinguished material featuring a deep, mirror-like finish with a glossy surface. Known for its durability and refined appearance, this stone offers a striking, reflective quality that adds a sense of luxury and depth to any interior setting. The polished surface enhances its aesthetic appeal, making it a perfect choice for creating elegant, high-end environments. Green Mirror Polish Stone combines strength with sophistication, ensuring a timeless and enduring finish that stands out in any application.",
    Category: "Indoor",
    ProductName: "Green Mirror",
  },
  {
    Type: "Natural",
    Material: "Stone",
    Variants: [
      {
        Sizes: [
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "18mm to 22mm",
          },
          {
            Length: "23 inches",
            Width: "23 inches",
            Thickness: "25mm to 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727588820179_1.png",
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727588820162_2.png",
            ],
            ColorName: "Grey",
          },
        ],
        Finish: "Mirror Polish",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_2000500597044558",
    CategoryID: "cat_17253562990392769",
    Description:
      "Grey Mirror Polish Stone is a top-tier material distinguished by its impeccable, mirror-like finish and glossy surface. Engineered for both strength and elegance, this stone offers a polished, reflective appearance that enhances the sophistication of any interior space. Its durability and refined aesthetic make it an excellent choice for high-end applications, delivering a timeless, professional look that endures. Ideal for creating sleek, modern environments, Grey Mirror Polish Stone is the epitome of luxury and resilience.",
    Category: "Indoor",
    ProductName: "Grey Mirror",
  },
  {
    Type: "Natural",
    Material: "Tandur Stone",
    Variants: [
      {
        Sizes: [
          {
            Length: "23 Inches",
            Width: "23 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Length: "23 Inches",
            Width: "23 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Length: "17 Inches",
            Width: "17 Inches",
            Thickness: "25mm - 30mm",
          },
          {
            Length: "17 Inches",
            Width: "17 Inches",
            Thickness: "25mm - 30mm",
          },
        ],
        Colors: [
          {
            Edge: "Machine Cut",
            Images: [
              "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727589772373_0000.png",
            ],
            ColorName: "Brown, Green, Black, Grey, & Yellow",
          },
        ],
        Finish: "Natural & Leather",
        VariantID: "001A",
      },
    ],
    ProductId: "prod_11850059734044558",
    CategoryID: "cat_17253559734044558",
    Description:
      "The Plus Pattern Stone offers a distinctive geometric design, adding a unique and contemporary look to outdoor spaces. Ideal for parking lots, garden pathways, and patios, this stone combines modern aesthetics with practical functionality. Available in a range of colors and sizes, the Plus Pattern Stone is built for outdoor durability and resilience. Its sturdy construction ensures it withstands the elements, making it a stylish and long-lasting choice for enhancing exterior areas.",
    ProductName: "Plus Pattern",
  },
];

const categoriesData = [
  {
    CategoryID: "cat_1725355554757286",
    CategoryName: "Slice Finish",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986947_Slice%20Polish.png",
  },
  {
    CategoryID: "cat_17253559734044558",
    CategoryName: "Parking/Garden",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986860_Parking.png",
  },
  {
    CategoryID: "cat_17253556539278027",
    CategoryName: "Leather Finish",

    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986843_Leather%20Finish_.png",
  },
  {
    CategoryID: "cat_1725355725154987",
    CategoryName: "Cobbles",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986986_Cobbles.png",
  },
  // {
  //   CategoryID: "cat_17253557791246696",
  //   CategoryName: "Diamond-Cut Finish",
  //   CategoryImage:
  //     "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726074513399_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_151944_0000.png",
  // },
  {
    CategoryID: "cat_17253558480386072",
    CategoryName: "Round Finish",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986890_Round%20Natural%20finish.png",
  },
  {
    CategoryID: "cat_17253558480386069",
    CategoryName: "Chapati Maker",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986731_Chapati%20maker.png",
  },
  {
    CategoryID: "cat_17253559205241743",
    CategoryName: "High Polish",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986993_High%20Polish.png",
  },
  // {
  //   CategoryID: "cat_17253559734044558",
  //   CategoryName: "Parking/Garden",
  //   CategoryImage:
  //     "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205337986_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240810_204425_0000.png",
  // },
  {
    CategoryID: "cat_11023559734044558",
    CategoryName: "Wall Stone",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986980_Wall%20Stone.png",
  },
  {
    CategoryID: "cat_17253560197007068",
    CategoryName: "Steps",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986958_Steps.png",
  },
  {
    CategoryID: "cat_17253561552887938", // need to add
    CategoryName: "Wall Cladding",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986971_Wall%20Cladding_.png",
  },
  {
    CategoryID: "cat_17253562110392769",
    CategoryName: "Satin Finish",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986903_Satin%20Finish.png",
  },
  {
    CategoryID: "cat_17253562990392769",
    CategoryName: "Mirror Finish",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986999_Mirror%20Polish.png",
  },
  // {
  //   CategoryID: "cat_17254613687023630",
  //   CategoryName: "Diamond Pattern",
  //   CategoryImage:
  //     "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205755783_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_143346_0000.png",
  // },
  // {
  //   CategoryID: "cat_17255138342343018",
  //   CategoryName: " Square Pattern",
  //   CategoryImage:
  //     "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726205586412_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_141440_0000.png",
  // },
  // {
  //   CategoryID: "cat_1725523795510555",
  //   CategoryName: "Line Pattern",
  //   CategoryImage:
  //     "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726206503882_Light%20Pink%20Salmon%20Skincare%20Product%20Features%20Instagram%20Post_20240808_150323_0000.png",
  // },
  {
    CategoryID: "cat_17255241012007679",
    CategoryName: "Slab",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986927_Slab.png",
  },
  {
    CategoryID: "cat_17255241012007669",
    CategoryName: "Roof Slab",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986878_Roof%20Slab.png",
  },
  {
    CategoryID: "cat_17200241012007679",
    CategoryName: "Natural Finish",
    CategoryImage:
      "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727698986723_Brown%20Natural%20finish_.png",
  },
];

const gallaryImages = [
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828492_IMG-20240704-WA0029.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828501_IMG-20240704-WA0055.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828502_IMG-20240630-WA0051.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828510_IMG-20240630-WA0054.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828519_IMG-20240630-WA0057.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828530_IMG-20240630-WA0059.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828542_IMG-20240630-WA0062.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828550_IMG-20240630-WA0063.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828560_IMG-20240630-WA0064.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828567_IMG-20240630-WA0065.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828573_IMG-20240630-WA0079.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828579_IMG-20240630-WA0080.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828587_IMG-20240630-WA0085.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828595_IMG-20240630-WA0089.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828599_IMG-20240630-WA0090.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828607_IMG-20240704-WA0026.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828618_IMG-20240704-WA0031.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828618_IMG-20240704-WA0049.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828631_IMG-20240704-WA0064.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828638_IMG-20240704-WA0076.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828644_IMG-20240704-WA0089.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828649_IMG-20240704-WA0109.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828653_IMG-20240704-WA0114.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828660_IMG-20240704-WA0124.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828666_IMG-20240711-WA0041.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828671_IMG-20240711-WA0042.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828679_IMG-20240711-WA0057.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828684_IMG-20240711-WA0059.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828694_IMG-20240711-WA0061.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828699_IMG-20240630-WA0084.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828704_IMG-20240630-WA0086.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828704_IMG-20240630-WA0087.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828715_IMG-20240704-WA0020.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828719_IMG-20240704-WA0021.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828728_IMG-20240704-WA0037.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828733_IMG-20240704-WA0042.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828737_IMG-20240704-WA0046.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828737_IMG-20240704-WA0047.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828746_IMG-20240704-WA0050.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828746_IMG-20240704-WA0051.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828761_IMG-20240704-WA0054.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828763_IMG-20240704-WA0057.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828766_IMG-20240704-WA0060.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828770_IMG-20240704-WA0074.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828775_IMG-20240704-WA0078.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828785_IMG-20240704-WA0094.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828790_IMG-20240704-WA0103.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828796_IMG-20240704-WA0127.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828799_IMG-20240711-WA0043.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828804_IMG-20240711-WA0046.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828810_IMG-20240711-WA0052.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828815_IMG-20240711-WA0060.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828819_IMG-20240711-WA0063.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828827_IMG-20240711-WA0064.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828831_IMG-20240711-WA0068.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1727592828836_IMG-20240716-WA0012.jpg",
];

const workImages = [
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400989785_IMG-20240704-WA0138.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400989790_IMG-20240704-WA0141.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400989938_IMG-20240711-WA0044.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400989951_IMG-20240711-WA0045.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400989962_IMG-20240711-WA0048.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400989970_IMG-20240711-WA0049.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400989983_IMG-20240711-WA0058.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400989997_IMG-20240711-WA0065.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990007_IMG-20240723-WA0041.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990019_IMG-20240723-WA0044.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990031_IMG-20240723-WA0045.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990044_IMG-20240723-WA0046.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990055_IMG-20240723-WA0048.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990065_IMG-20240723-WA0049.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990076_IMG-20240723-WA0050.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990092_IMG-20240723-WA0051.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990103_IMG-20240711-WA0040.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990116_IMG-20240711-WA0050.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990129_IMG-20240711-WA0051.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990142_IMG-20240711-WA0053.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990150_IMG-20240711-WA0067.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990151_IMG-20240711-WA0071.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990165_IMG-20240723-WA0042.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990178_IMG-20240723-WA0043.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990192_IMG-20240723-WA0047.jpg",
  "https://acenaturalstns.s3.ap-south-1.amazonaws.com/uploads/1726400990201_IMG-20240723-WA0052.jpg",
];

export { productsData, categoriesData, gallaryImages, workImages };
